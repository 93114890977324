<template>
  <div class="page">
    <SocketWatch :tables="['montages']" @loadData="loadData" />
    <DateFilterAssignedMontages @updDate="updDate" />

    <div class="px-3 mt-3">
      <v-card>
        <!-- <v-skeleton-loader
          v-if="!montages || montages.length === 0"
          class="pl-3 pr-3"
          type="table-thead, table-row-divider@3"
          :types="skeletonTypesIframe"
        /> -->
        <div 
          v-if="!montages || montages.length === 0"
          class="pa-3 d-flex justify-center"
        >
          Нет монтажей
        </div>
        <v-simple-table v-if="montages && montages.length > 0">
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Дата</th>
                <th class="text-left">Клиент</th>
                <th class="text-left">Что делать</th>
                <th class="text-left">Статус</th>
                <th class="text-left">Менеджер</th>
              </tr>
            </thead>
            <tbody>
              <tr 
                v-for="montage in montages"
                :key="montage.id"
                @click="editMontage(montage)"
                class="tableItemList"
              >
                <td style="min-width: 80px;">{{ $formatDate(new Date(montage.date)) }}</td>
                <td style="min-width: 125px;">{{ montage.client.name }}</td>
                <td style="max-width: 280px;">
                  {{ $sliceStr($getStrTasks(montage.tasks), 125) }}
                </td>
                <td>{{ montagesStatus[montage.status] }}</td>
                <td>{{ montage.manager.displayName }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card>
    </div>
  </div>
</template>

<script>
import DateFilterAssignedMontages from '@/components/Montages/DateFilterAssignedMontages.vue'
import MontageModal from  '@/components/Modals/Montage/Index'
import { montagesStatus } from '@/helpers'

export default {
  name: 'AssignedMontagesInBrigForMaster',
  props: ['data'],
  components: {
    DateFilterAssignedMontages
  },
  data: () => ({
    montagesStatus: montagesStatus,
    skeletonTypesIframe: {
      'table-thead': 'heading@4',
      'table-row': 'table-cell@4'
    }
  }),
  created() {
    this.loadData()
  },
  computed: {
    montages() {
      return this.$store.getters['montages/getMontagesForMontagerProfile']
    },
    user() {
      return this.$store.getters['user/getUser']
    },
    filterMontagesByDate: {
      get() { return this.$store.getters['montages/getFilterAssignedMontagesByDate'] },
      set(val) { this.$store.commit('montages/setFilterAssignedMontagesByDate', val) }
    }
  },
  methods: {
    loadData() {
      this.$store.dispatch('montages/loadMontagesForMontagerProfile', {
        id: this.user.id,
        date: this.filterMontagesByDate
      })
    },
    updDate(date) {
      this.filterMontagesByDate = date
      this.$store.dispatch('montages/loadMontagesForMontagerProfile', {
        id: this.user.id,
        date: date
      })
    },
    showMontage(montage) {
      console.log(montage)
    },
    async editMontage(montage) {
      await this.$showModal({
        component: MontageModal,
        isPersistent: true,
        props: {
          edit: true,
          data: montage
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.tableMontage {
  &_tr {
    &_cont {
      &:hover {
        background-color: rgba(199, 199, 199, 0.26);
      }

      &_status {
        width: 0.7%;

        div {
          width: 100%;
          height: 100%;
        }
      }

      &_info {
        width: 92.3%;
        display: flex;
        align-items: center;

        &:hover {
          cursor: pointer;
        }

        &_container {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      &_edit {
        width: 7%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    &:last-child {
      .tableMontage_tr_cont .tableMontage_tr_cont_status div {
        border-bottom-left-radius: 5px;
      }
    }
  }
}
</style>